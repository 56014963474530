import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import Grow, { GrowProps } from '@mui/material/Grow';

export default function Notification({
  close,
  message,
  severity,
}: {
  close: (message: string) => void;
  message: string;
  severity: AlertColor;
}) {
  function handleClose() {
    close(message);
  }

  function GrowTransition(props: GrowProps) {
    return <Grow {...props} />;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={GrowTransition}
      open={true}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
