import { lazy, RefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPhone, Person2, PermDeviceInformation, Logout } from '@mui/icons-material';
import { Popover, Divider, Paper, MenuList, MenuItem, ListItemText, ListItemIcon } from '@mui/material';

import type { ContactsPopup, ProfilePopup, SysinfoPopup } from '@/components';
import { ctxPopup } from '@/contexts/PopupContext';
import { ctxUser } from '@/contexts/UserContext';

const ContactsPopupLazy = lazy(() => import('@/components/contacts/ContactsPopup')) as typeof ContactsPopup;
const ProfilePopupLazy = lazy(() => import('@/components/profile/ProfilePopup')) as typeof ProfilePopup;
const SysinfoPopupLazy = lazy(() => import('@/components/sysinfo/SysinfoPopup')) as typeof SysinfoPopup;

export default function More({
  close,
  ref,
  logout,
}: {
  close: () => void;
  ref: RefObject<HTMLAnchorElement | null>;
  logout: () => void;
}) {
  const popupCtx = useContext(ctxPopup);
  const userCtx = useContext(ctxUser);
  const { t } = useTranslation();

  if (!popupCtx) return;

  return (
    <Popover
      open={true}
      anchorEl={ref.current}
      onClose={close}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Paper sx={{ width: 230, maxWidth: '100%' }}>
        <MenuList>
          <MenuItem
            onClick={() => popupCtx.setPopupMain(<ContactsPopupLazy close={close} />)}
            data-qa-element-id="more-list-item-contacts"
          >
            <ListItemIcon>
              <ContactPhone fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('mainMenu.contacts')}</ListItemText>
          </MenuItem>
          {!userCtx?.isToken() && (
            <MenuItem
              onClick={() => popupCtx.setPopupMain(<ProfilePopupLazy close={close} />)}
              data-qa-element-id="more-list-item-profile"
            >
              <ListItemIcon>
                <Person2 fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('mainMenu.profile')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => popupCtx.setPopupMain(<SysinfoPopupLazy close={close} />)}
            data-qa-element-id="more-list-item-sysinfo"
          >
            <ListItemIcon>
              <PermDeviceInformation fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('mainMenu.systemInformation')}</ListItemText>
          </MenuItem>
          {!userCtx?.isToken() && (
            <>
              <Divider />
              <MenuItem onClick={() => logout()} data-qa-element-id="more-list-item-logout">
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('mainMenu.logout')}</ListItemText>
              </MenuItem>
            </>
          )}
        </MenuList>
      </Paper>
    </Popover>
  );
}
