import './TokenFail.css';

export function TokenFail() {
  return (
    <div id="token-fail-container">
      <h1>(HUN) A hozzáférésre pillanatnyilag nem jogosult.</h1>
      <h1>(ENG) You are currently not authorized to access.</h1>
      <h1>(RON) Valabilitatea accesului a expirat.</h1>
      <h1>(SRP) Vreme dozvoljenog pristupa isteklo.</h1>
      <h1>(GER) Sie sind derzeit nicht zugriffsberechtigt.</h1>
      <h1>(SWE) Du är för tillfället ej behörig för åtkomst.</h1>
      <h1>(ESP) De momento, su acceso no está autorizado.</h1>
      <h1>(SWE) Du är för tillfället ej behörig för åtkomst.</h1>
      <h1>(NED) U bent momenteel niet gemachtigd tot toegang.</h1>
      <h1>(FRA) Votre accès n'est pas disponible pour le moment.</h1>
    </div>
  );
}
