import { useContext, useEffect } from 'react';
import { isToday } from 'date-fns';

import { useUserMessage } from '@/hooks/useUserMessage';
import { ctxNotification } from '@/contexts/NotificationCtx';

// periodikusan megnezni, van-e az user szamara itrack uzenet
// ha van akkor kiteszi notification-be
// a megfelelo szabalyok szerint
export default function UserMessages() {
  const ctxNotify = useContext(ctxNotification);
  const message = useUserMessage(); // ez periodikusan visszater az uj uzenettel

  useEffect(() => {
    function closeNews() {
      const currentTimeMillis = Date.now();
      localStorage.setItem('userMessageTimeMillis', String(currentTimeMillis));
    }

    const userMessageTimeMillis = localStorage.getItem('userMessageTimeMillis') || null;
    let showMessage;
    if (userMessageTimeMillis) {
      const messageShownDate = new Date(Number(userMessageTimeMillis));
      showMessage = !isToday(messageShownDate);
    } else {
      showMessage = true;
    }

    if (message?.message && showMessage) {
      ctxNotify?.showNotification('warning', message.message, closeNews);
    }
    // eslint-disable-next-line
  }, [message]);

  return <></>;
}
