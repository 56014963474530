import { lazy, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';

import { HomeBottomNav, HomeLeftNav } from './HomeNav';

import GlobalStates from './GlobalStates';
import Popups from './Popups';
import UserMessages from './UserMessages';
import { RoutesContext } from '@/contexts/RoutesCtx';
import { MessagesContext } from '@/contexts/MessagesCtx';

import './Home.css';
import { isPhoneOrHighScreen } from '@/utilities/sizeUtils';

// TODO itt valamiert nem megy a tipusossag mint a tobbi lazy-nel...
const MapLazy = lazy(() => import('@/components/map/Map'));

export default function Home({ logout }: { logout: () => void }) {
  const [isPhone, setPhone] = useState<boolean>(isPhoneOrHighScreen);

  useEffect(() => {
    function handleResize() {
      setPhone(isPhoneOrHighScreen);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <GlobalStates>
      <RoutesContext>
        <MessagesContext>
          <Popups>
            <>
              <UserMessages />
              <Box className="home">
                {isPhone ? (
                  <Container component="main" className="home-main-phone" maxWidth={false}>
                    <MapLazy />
                    <HomeBottomNav logout={logout} />
                  </Container>
                ) : (
                  <Container component="main" className="home-main" maxWidth={false}>
                    <HomeLeftNav logout={logout} />
                    <MapLazy />
                  </Container>
                )}
              </Box>
            </>
          </Popups>
        </MessagesContext>
      </RoutesContext>
    </GlobalStates>
  );
}
