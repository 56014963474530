import { ChangeEvent, useState, useRef } from 'react';
import { Close as ClearText, Search as SearchIcon } from '@mui/icons-material';
import { InputBase, styled, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CSS from 'csstype';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  marginRight: 0,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export function SearchComponent({
  searchText,
  setSearchText,
  autoFocus,
}: {
  searchText: string | undefined;
  setSearchText: (searchText: string) => void;
  autoFocus: boolean;
}) {
  const [searchTextLocal, setSearchTextLocal] = useState<string | undefined>(searchText);
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);
  const [clearTextButtonStyle, setClearTextButtonStyle] = useState<CSS.Properties>({
    position: 'absolute',
    right: '4px',
    top: '7px',
    display: !searchText ? 'none' : 'inline-flex',
  });

  function setSearchTextWrapper(searchText: string) {
    if (searchText.length != 0) {
      setClearTextButtonStyle({
        ...clearTextButtonStyle,
        display: 'inline-flex',
      });
    } else {
      setClearTextButtonStyle({
        ...clearTextButtonStyle,
        display: 'none',
      });
    }
    setSearchTextLocal(searchText);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setSearchText(searchText);
      timer.current = undefined;
    }, 300);
  }
  const { t } = useTranslation();

  function handleClearTextButtonClick() {
    setSearchTextLocal('');
    setSearchText('');
    setClearTextButtonStyle({
      ...clearTextButtonStyle,
      display: 'none',
    });
  }

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          autoFocus={autoFocus}
          value={searchTextLocal || ''}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => setSearchTextWrapper(evt.target.value)}
          placeholder={t('filter.targetSearchPlaceholder')}
        />
        <ClearText onClick={() => handleClearTextButtonClick()} style={clearTextButtonStyle} />
      </Search>
    </>
  );
}
