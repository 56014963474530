import { ReactElement, useState } from 'react';

import { ctxPopup } from '@/contexts/PopupContext';
import Spinner from '@/components/common/Spinner';

export default function Popups({ children }: { children: ReactElement }) {
  const [popupMain, setPopupMain] = useState<ReactElement | undefined>(undefined);
  const [popupSecondary, setPopupSecondary] = useState<ReactElement | undefined>(undefined);
  const [popupSpinner, setPopupSpinner] = useState<ReactElement | undefined>(undefined);

  // TODO ne lehessen secondary-t masikkal felulirni ?
  // TODO lehessen tobb notification is ??
  // szamolnia spinner kerest/torlest ?

  function showSpinner(centered: boolean) {
    setPopupSpinner(<Spinner centered={centered} />);
  }
  function hideSpinner() {
    setPopupSpinner(undefined);
  }

  return (
    <ctxPopup.Provider
      value={{
        setPopupMain,
        setPopupSecondary,
        setPopupSpinner,
        showSpinner,
        hideSpinner,
      }}
    >
      {popupMain}
      {popupSecondary}
      {popupSpinner}
      {children}
    </ctxPopup.Provider>
  );
}
