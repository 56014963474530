import { Dispatch, ReactElement, SetStateAction, createContext, useState } from 'react';
import { TabValue } from '@/types';
import { TargetDescription } from '@/tmp';
import { Refuel } from '@/tmp';

export type RefuelContextType = {
  tab: TabValue;
  setTab: Dispatch<SetStateAction<TabValue>>;
  selectedTarget: TargetDescription | undefined;
  setSelectedTarget: Dispatch<SetStateAction<TargetDescription | undefined>>;
  selectedDates: { from: Date; to: Date } | undefined;
  setSelectedDates: Dispatch<SetStateAction<{ from: Date; to: Date } | undefined>>;
  refuels: Refuel[] | undefined;
  setRefuels: Dispatch<SetStateAction<Refuel[] | undefined>>;
  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
};

// lehet ki kellene tenni a contexts-be...
// eslint-disable-next-line react-refresh/only-export-components
export const ctxRefuel = createContext<RefuelContextType | undefined>(undefined);

export function RefuelContext({ children }: { children: ReactElement }) {
  const [tab, setTab] = useState<TabValue>(TabValue.targetSelect);
  const [selectedTarget, setSelectedTarget] = useState<TargetDescription | undefined>(undefined);
  const [selectedDates, setSelectedDates] = useState<{ from: Date; to: Date } | undefined>(undefined);
  const [refuels, setRefuels] = useState<Refuel[] | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  return (
    <ctxRefuel.Provider
      value={{
        tab,
        setTab,
        selectedTarget,
        setSelectedTarget,
        selectedDates,
        setSelectedDates,
        refuels,
        setRefuels,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </ctxRefuel.Provider>
  );
}
