class TrackingResponseHandler {
  private attempts: number;
  private maxAttempts: number;

  constructor(maxAttempts = 3) {
    this.attempts = 0;
    this.maxAttempts = maxAttempts;
  }

  public successfulRequest() {
    this.attempts = 0;
  }

  public handleFailedRequest(): boolean {
    this.attempts++;
    if (this.attempts >= this.maxAttempts) {
      this.attempts = 0;
      return false;
    }
    return true;
  }
}

export default TrackingResponseHandler;
