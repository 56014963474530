import { Dispatch, ReactElement, SetStateAction, createContext, useState } from 'react';
import { TabValue } from '@/types';
import { TargetDescription } from '@/tmp';
import { Route } from '@/tmp';

export type RoutesContextType = {
  tab: TabValue;
  setTab: Dispatch<SetStateAction<TabValue>>;
  selectedTarget: TargetDescription | undefined;
  setSelectedTarget: Dispatch<SetStateAction<TargetDescription | undefined>>;
  selectedDates: { from: Date; to: Date } | undefined;
  setSelectedDates: Dispatch<SetStateAction<{ from: Date; to: Date } | undefined>>;
  routes: Route[] | undefined;
  setRoutes: Dispatch<SetStateAction<Route[] | undefined>>;
  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
  multiSelectionMode: boolean;
  setMultiSelectionMode: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const ctxRoutes = createContext<RoutesContextType | undefined>(undefined);

export function RoutesContext({ children }: { children: ReactElement }) {
  const [tab, setTab] = useState<TabValue>(0);
  const [selectedTarget, setSelectedTarget] = useState<TargetDescription | undefined>(undefined);
  const [selectedDates, setSelectedDates] = useState<{ from: Date; to: Date } | undefined>(undefined);
  const [routes, setRoutes] = useState<Route[] | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [multiSelectionMode, setMultiSelectionMode] = useState<boolean>(true);

  return (
    <ctxRoutes.Provider
      value={{
        tab,
        setTab,
        selectedTarget,
        setSelectedTarget,
        selectedDates,
        setSelectedDates,
        routes,
        setRoutes,
        searchText,
        setSearchText,
        multiSelectionMode,
        setMultiSelectionMode,
      }}
    >
      {children}
    </ctxRoutes.Provider>
  );
}
