import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Container, LinearProgress } from '@mui/material';

import './PleaseWait.css';

export function PleaseWait() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Card className="please-wait-card">
        <CardContent>
          <Typography variant="h5" component="div">
            {t('general.waitingForServer')}
          </Typography>
          <Typography variant="body2">
            <LinearProgress />
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
