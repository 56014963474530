import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './Spinner.css';

export default function Spinner({ centered }: { centered: boolean }) {
  return (
    <Box className={centered ? 'spinner-centered' : 'spinner-lefttop'}>
      <CircularProgress color={centered ? undefined : 'secondary'} />
    </Box>
  );
}
