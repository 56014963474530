import { User, UserAndMessage } from './User';
import { abortTracking } from '@/tmp';
import axios from 'axios';

// ha nincs bejelentkezve, akkor null-t adunk vissza
// undefined nem jo, mert az azt jelenti, hogy nem tudjuk, van-e session
// null-nal tudjuk, hogy nincs
export async function getUser() {
  const url = '/api/v1.1/users/current';
  return axios.get<UserAndMessage>(url);
}
  
export function logoutUser(): void {
  abortTracking();
  const url = '/api/v1.1/logout';
  // FIXME nem kellene ennek post-nak lennie ???
  axios.get<User>(url);
}

export async function loginUser({username, password, rememberMe}: {username: string, password: string, rememberMe?: string}): Promise<User> {
  // TODO remove this
  if (username === undefined || password === undefined) {
    throw new Error('Username and password must be provided');
  }

  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  if (rememberMe === "on") {
    params.append("remember-me", "true");
  }

  // FIXME itt egybol visszajohetne az user...
  const response = await axios.post('/api/v1.1/login', params);

  // ha nem ok akkor axios hibat dob ???
  if (response.status > 299) throw new Error('invalid user');

  // betoltjuk az user-t kulon :(
  const user = await getUser();
  return user.data;
}
