import { Dispatch, SetStateAction, ReactElement, createContext } from 'react';

export type PopupContext = {
  setPopupMain: Dispatch<SetStateAction<ReactElement | undefined>>;
  setPopupSecondary: Dispatch<SetStateAction<ReactElement | undefined>>;
  setPopupSpinner: Dispatch<SetStateAction<ReactElement | undefined>>;

  showSpinner: (centered: boolean) => void;
  hideSpinner: () => void;
};

export const ctxPopup = createContext<PopupContext | undefined>(undefined);
