import { FallbackProps } from 'react-error-boundary';
import Box from '@mui/material/Box';

import './Error.css';

export default function Error({ error, resetErrorBoundary }: FallbackProps) {
  console.log(error);

  return (
    <Box className="error-dialog">
      <h1>Something terrible has happened</h1>
      <button className="error-dialog-button" type="button" onClick={resetErrorBoundary}>
        retry
      </button>
    </Box>
  );
}
// legyen close gomb is
