import { lazy, useCallback } from 'react';

import type { Home, LoginPage } from '@/components';
import { PleaseWait } from '@/components';
import { TokenFail } from './components/common/TokenFail';
import { UserState } from '@/types';
import { useUserState } from '@/hooks/useUserState';
import { ctxUser } from '@/contexts/UserContext';
import { User } from '@/tmp';
import { HandleErrors } from './HandleErrors';

const HomeLazy = lazy(() => import('@/components/home/Home')) as typeof Home;
const LoginPageLazy = lazy(() => import('@/components/login/LoginPage')) as typeof LoginPage;

type FlutterMessage = {
  postMessage: (message: string) => void;
};
declare global {
  // eslint-disable-next-line no-var
  var Logout: FlutterMessage, continueTracking: () => void;
}

function App() {
  const [user, setUser] = useUserState(undefined);

  const logout = useCallback(async () => {
    setUser(null);
    localStorage.removeItem('userMessageTimeMillis');
    if (window.Logout) {
      window.Logout.postMessage('Logout clicked');
    }
    // eslint-disable-next-line
    window.continueTracking = function () {};
  }, [setUser]);

  function loggedIn(user: UserState) {
    setUser(user);
  }

  function RenderLogin() {
    return (
      <HandleErrors logout={() => {}}>
        <LoginPageLazy loggedIn={loggedIn} />
      </HandleErrors>
    );
  }

  function RenderApp() {
    return (
      <ctxUser.Provider
        value={{
          user: user as User,
          isToken: () => (user as User)?.isTokenUser,
        }}
      >
        <HandleErrors logout={logout}>
          <HomeLazy logout={logout} />
        </HandleErrors>
      </ctxUser.Provider>
    );
  }

  switch (user) {
    case undefined:
      return <PleaseWait />;
    case null:
      return <RenderLogin />;
    case 'token_failure':
      return <TokenFail />;
    default:
      return <RenderApp />;
  }
}

export default App;
